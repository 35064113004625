"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.italia = void 0;
exports.italia = {
    50: "#E3F2FD",
    100: "#BBDEFB",
    200: "#90CAF9",
    300: "#1976D2",
    400: "#42A5F5",
    500: "#0073E6",
    600: "#1E88E5",
    700: "#0059B2",
    800: "#1565C0",
    900: "#0D47A1",
};
