"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterLegal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const FooterLegal = ({ content }) => ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
        borderTop: 1,
        borderColor: "divider",
        backgroundColor: "background.paper",
    } }, { children: (0, jsx_runtime_1.jsx)(material_1.Container, Object.assign({ sx: { px: 2, py: 2 } }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ color: "text.primary", component: "p", variant: "caption", sx: { textAlign: "center" } }, { children: content })) })) })));
exports.FooterLegal = FooterLegal;
