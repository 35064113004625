"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllusEmail = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const Illustration_1 = require("../components/Illustration");
const IllusEmail = (_a) => {
    var { title = "Email" } = _a, rest = tslib_1.__rest(_a, ["title"]);
    return ((0, jsx_runtime_1.jsx)(Illustration_1.Illustration, Object.assign({ name: title }, rest, { children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M116 89.4856C116 92.4976 114.833 95.3295 112.712 97.4594C110.592 99.5891 107.772 100.762 104.773 100.762L15.5143 100.762C9.32387 100.762 4.28747 95.7035 4.28711 89.4856V32.039C4.28729 28.93 5.54634 26.1109 7.57927 24.069C9.61219 22.0272 12.419 20.7626 15.5143 20.7624L104.773 20.7622C110.964 20.7622 116 25.821 116 32.0388L116 89.4856ZM10.0912 92.2891L43.9154 58.3161L12.4699 26.7321C10.644 27.793 9.40971 29.7719 9.40953 32.039L9.40935 89.4854C9.40935 90.4961 9.65819 91.4478 10.0912 92.2891ZM64.4604 63.0039C62.0801 65.3947 58.2073 65.3947 55.827 63.0039L18.8932 25.9075L101.394 25.9074L64.4604 63.0039ZM104.773 95.6174C105.231 95.6176 105.68 95.5656 106.116 95.4675C97.4005 86.7137 92.5737 81.8655 86.3341 75.5984L72.75 61.9543L68.0827 66.6422C63.7052 71.039 56.5824 71.0388 52.2051 66.6422L47.5378 61.9543L14.175 95.4639C14.6068 95.5614 15.0539 95.6172 15.5143 95.6172L104.773 95.6174ZM110.201 92.2944L76.3719 58.3161L107.818 26.7321C109.644 27.7932 110.878 29.7721 110.878 32.039V89.4856C110.878 90.4794 110.643 91.4362 110.201 92.2944Z" }) })));
};
exports.IllusEmail = IllusEmail;
