"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAvatar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styles_1 = require("@mui/material/styles");
const _theme_1 = require("../../theme/index");
const sizesMap = {
    small: {
        dimension: 32,
        padding: 0.5 /* 4px */,
    },
    default: {
        dimension: 64,
        padding: 1 /* 8px */,
    },
    large: {
        dimension: 88,
        padding: 2 /* 16px */,
    },
};
const ProductAvatar = ({ size = "default", id, logoUrl, logoBgColor, logoAltText, }) => {
    var _a, _b, _c;
    return ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ id: id, sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            width: (_a = sizesMap[size]) === null || _a === void 0 ? void 0 : _a.dimension,
            height: (_b = sizesMap[size]) === null || _b === void 0 ? void 0 : _b.dimension,
            backgroundColor: logoBgColor
                ? logoBgColor
                : _theme_1.theme.palette.background.paper,
            boxSizing: "border-box",
            padding: _theme_1.theme.spacing((_c = sizesMap[size]) === null || _c === void 0 ? void 0 : _c.padding),
            borderRadius: _theme_1.theme.spacing(1) /* 8px */,
            /* Inner shadow */
            "&:after": {
                content: "''",
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                /* 10% Black inner border if background is set,
                otherwise a light grey inner border */
                boxShadow: logoBgColor
                    ? `inset 0 0 0 1px ${(0, styles_1.alpha)(_theme_1.theme.palette.common.black, 0.1)}`
                    : `inset 0 0 0 1px ${_theme_1.theme.palette.divider}`,
                borderRadius: "inherit",
            },
        } }, { children: (0, jsx_runtime_1.jsx)("img", { src: logoUrl, alt: logoAltText, style: {
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
            } }) })));
};
exports.ProductAvatar = ProductAvatar;
