"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllusHistoryDoc = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const Illustration_1 = require("../components/Illustration");
const IllusHistoryDoc = (_a) => {
    var { title = "Storico del documento" } = _a, rest = tslib_1.__rest(_a, ["title"]);
    return ((0, jsx_runtime_1.jsxs)(Illustration_1.Illustration, Object.assign({ name: title }, rest, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M43.255 79.504h-15.81a2.135 2.135 0 0 0-2.134 2.13c0 1.174.958 2.13 2.134 2.13h15.81a2.135 2.135 0 0 0 2.134-2.13c0-1.174-.958-2.13-2.135-2.13ZM43.255 86.92h-15.81a2.135 2.135 0 0 0-2.134 2.13c0 1.174.958 2.13 2.134 2.13h15.81a2.135 2.135 0 0 0 2.134-2.13c0-1.174-.958-2.13-2.135-2.13Z" }), (0, jsx_runtime_1.jsx)("path", { d: "M106 69.162c0-12.639-9.782-23.04-22.189-24.062v-8.628c0-1.453-.59-2.877-1.62-3.904L67.213 17.616A5.58 5.58 0 0 0 63.3 16H22.505C18.367 16 15 19.36 15 23.49V97.51c0 4.129 3.367 7.489 7.505 7.489h53.801a7.498 7.498 0 0 0 6.55-3.838s.955-1.513.955-4.598v-3.34c12.405-1.022 22.187-11.422 22.187-24.062H106Zm-39.158-45.89 9.685 9.665h-6.448a3.236 3.236 0 0 1-3.237-3.23v-6.435Zm9.466 77.468H22.505a3.236 3.236 0 0 1-3.237-3.23V23.49a3.236 3.236 0 0 1 3.237-3.23h40.069v9.446c0 4.13 3.367 7.49 7.505 7.49h9.466v7.926c-12.285 1.14-21.937 11.486-21.937 24.04 0 12.554 9.652 22.9 21.937 24.041v4.307a3.236 3.236 0 0 1-3.237 3.23Zm5.495-11.69c-10.989 0-19.929-8.921-19.929-19.888 0-10.966 8.94-19.887 19.929-19.887 10.989 0 19.929 8.921 19.929 19.887 0 10.967-8.94 19.888-19.93 19.888Z" }), (0, jsx_runtime_1.jsx)("path", { d: "m93.522 74.847-9.585-5.97V57.72c0-1.176-.956-2.13-2.134-2.13a2.132 2.132 0 0 0-2.134 2.13v12.336c0 .736.38 1.418 1.005 1.806l10.59 6.595c.352.22.743.324 1.13.324a2.13 2.13 0 0 0 1.811-1.003 2.127 2.127 0 0 0-.68-2.935l-.003.003Z" })] })));
};
exports.IllusHistoryDoc = IllusHistoryDoc;
