"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hero = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const HERO_TEXT_PADDING = { xs: 4, sm: 4, md: 8 };
const HeroTextContent = ({ title, subtitle, ctaPrimary, ctaSecondary, }) => ((0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: 4 }, { children: [(0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: 2 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h1", color: "primary.contrastText" }, { children: title })), (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [subtitle && typeof subtitle === "string" && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "body1", color: "primary.contrastText" }, { children: subtitle }))), subtitle && typeof subtitle !== "string" && subtitle] })] })), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: "row", spacing: 2 }, { children: [ctaPrimary && ((0, jsx_runtime_1.jsx)(material_1.Box, { children: (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ "aria-label": ctaPrimary.title, variant: "contained", color: "negative", onClick: ctaPrimary.onClick, component: "a" }, { children: ctaPrimary.label })) })), ctaSecondary && ((0, jsx_runtime_1.jsx)(material_1.Box, { children: (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ "aria-label": ctaSecondary.title, color: "negative", variant: "outlined", onClick: ctaSecondary.onClick, component: "a" }, { children: ctaSecondary.label })) }))] }))] })));
const HeroJustText = (props) => ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: { maxWidth: "40rem", margin: "0 auto", py: HERO_TEXT_PADDING } }, { children: (0, jsx_runtime_1.jsx)(HeroTextContent, Object.assign({}, props)) })));
const HeroWithImage = (_a) => {
    var { inverse, image, altText = "Hero Image" } = _a, props = tslib_1.__rest(_a, ["inverse", "image", "altText"]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: {
                display: "grid",
                columnGap: 3,
                rowGap: 5,
                gridTemplateColumns: {
                    xs: "repeat(6, minmax(0, 1fr))",
                    md: "repeat(12, minmax(0, 1fr))",
                },
                py: HERO_TEXT_PADDING,
            } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ gridColumn: {
                        xs: "span 6",
                        md: inverse ? "7 / span 5" : "2 / span 5",
                    }, gridRow: {
                        xs: "auto",
                        md: 1,
                    }, my: "auto" }, { children: (0, jsx_runtime_1.jsx)(HeroTextContent, Object.assign({}, props)) })), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ gridColumn: {
                        xs: "span 6",
                        md: inverse ? "2 / span 5" : "7 / span 5",
                    }, gridRow: {
                        xs: "auto",
                        md: 1,
                    }, alignSelf: "center" }, { children: (0, jsx_runtime_1.jsx)("img", { alt: altText, src: image, style: {
                            objectFit: "contain",
                            objectPosition: "center",
                            width: "100%",
                            height: "100%",
                            maxHeight: "600px",
                            userSelect: "none",
                        } }) }))] })) }));
};
const Hero = (props) => ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ bgcolor: "primary.main", sx: {
        backgroundImage: `url(${props.background})`,
        backgroundSize: "cover",
    } }, { children: (0, jsx_runtime_1.jsx)(material_1.Container, Object.assign({ maxWidth: "xl" }, { children: props.type === "image" ? ((0, jsx_runtime_1.jsx)(HeroWithImage, Object.assign({}, props))) : ((0, jsx_runtime_1.jsx)(HeroJustText, Object.assign({}, props))) })) })));
exports.Hero = Hero;
