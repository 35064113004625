"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllusSms = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const Illustration_1 = require("../components/Illustration");
const IllusSms = (_a) => {
    var { title = "Sms" } = _a, rest = tslib_1.__rest(_a, ["title"]);
    return ((0, jsx_runtime_1.jsxs)(Illustration_1.Illustration, Object.assign({ name: title }, rest, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M62.344 38.906a2.345 2.345 0 0 0 0 4.688 2.345 2.345 0 0 0 0-4.688ZM62.344 52.969a2.345 2.345 0 0 0 0 4.687 2.345 2.345 0 0 0 0-4.687ZM29.531 105.938a2.344 2.344 0 0 0 0 4.687 2.345 2.345 0 0 0 0-4.687ZM69.375 41.25a2.344 2.344 0 0 0 2.344 2.344h28.125a2.344 2.344 0 1 0 0-4.688H71.719a2.344 2.344 0 0 0-2.344 2.344ZM99.844 52.969H71.719a2.344 2.344 0 1 0 0 4.687h28.125a2.344 2.344 0 1 0 0-4.687Z" }), (0, jsx_runtime_1.jsx)("path", { d: "M62.344 0H15.469C9.007 0 3.75 5.257 3.75 11.719v96.562C3.75 114.743 9.007 120 15.469 120h46.875c6.461 0 11.718-5.257 11.718-11.719V77.578l7.813-5.86h27.344a7.039 7.039 0 0 0 7.031-7.03V31.874a7.039 7.039 0 0 0-7.031-7.031H74.063V11.719C74.063 5.257 68.805 0 62.343 0ZM15.469 4.688h46.875c3.055 0 5.66 1.959 6.628 4.687H8.84c.968-2.728 3.574-4.688 6.629-4.688Zm46.875 110.624H15.469a7.038 7.038 0 0 1-7.031-7.031v-7.031h60.937v7.031a7.038 7.038 0 0 1-7.031 7.031Zm49.218-83.437v32.813a2.346 2.346 0 0 1-2.343 2.343H81.094c-.507 0-1 .165-1.406.469l-15 11.25v-9.375a2.344 2.344 0 0 0-2.344-2.344h-9.375a2.346 2.346 0 0 1-2.344-2.344V31.876a2.346 2.346 0 0 1 2.344-2.344h56.25a2.346 2.346 0 0 1 2.343 2.344Zm-58.593-7.031a7.039 7.039 0 0 0-7.032 7.031v32.813a7.039 7.039 0 0 0 7.032 7.03H60v11.72a2.345 2.345 0 0 0 3.75 1.874l5.625-4.218v15.469H8.437v-82.5h60.938v10.78H52.969Z" }), (0, jsx_runtime_1.jsx)("path", { d: "M48.281 105.938h-9.375a2.343 2.343 0 1 0 0 4.687h9.375a2.344 2.344 0 1 0 0-4.687Z" })] })));
};
exports.IllusSms = IllusSms;
