"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotificationContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const material_1 = require("@mui/material");
const _theme_1 = require("../../theme/index");
const TimelineNotificationContent = (props) => {
    const { children } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.TimelineContent, Object.assign({ sx: Object.assign({ py: 0, px: _theme_1.theme.spacing(2.5), my: _theme_1.theme.spacing(2.5) }, props.sx) }, { children: (0, jsx_runtime_1.jsx)(material_1.Stack, Object.assign({ spacing: 1, alignItems: "baseline" }, { children: children })) })));
};
exports.TimelineNotificationContent = TimelineNotificationContent;
