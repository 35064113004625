"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonNaked = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const ButtonNaked = (props) => {
    const { children, buttonRef, color = "text", weight = "default" } = props, rest = tslib_1.__rest(props, ["children", "buttonRef", "color", "weight"]);
    return ((0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ sx: {
            fontWeight: weight === "default" ? "600" : "400",
            letterSpacing: 0.3,
        }, variant: "naked", size: "small", color: color, disableRipple: true, disableTouchRipple: true, ref: buttonRef }, rest, { children: children })));
};
exports.ButtonNaked = ButtonNaked;
