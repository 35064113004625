"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Footer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const FooterLegal_1 = require("../FooterLegal");
const FooterPostLogin_1 = require("../FooterPostLogin");
const FooterPreLogin_1 = require("../FooterPreLogin");
const Footer = ({ companyLink, postLoginLinks, preLoginLinks, legalInfo, loggedUser, onExit, languages, onLanguageChanged, currentLangCode, productsJsonUrl, onProductsJsonFetchError, hideProductsColumn, }) => ((0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ component: "footer" }, { children: [loggedUser ? ((0, jsx_runtime_1.jsx)(FooterPostLogin_1.FooterPostLogin, { companyLink: companyLink, links: postLoginLinks, onExit: onExit, languages: languages, onLanguageChanged: onLanguageChanged, currentLangCode: currentLangCode })) : ((0, jsx_runtime_1.jsx)(FooterPreLogin_1.FooterPreLogin, { companyLink: companyLink, links: preLoginLinks, languages: languages, onLanguageChanged: onLanguageChanged, currentLangCode: currentLangCode, productsJsonUrl: productsJsonUrl, onProductsJsonFetchError: onProductsJsonFetchError, hideProductsColumn: hideProductsColumn, onExit: onExit })), (0, jsx_runtime_1.jsx)(FooterLegal_1.FooterLegal, { content: legalInfo })] })));
exports.Footer = Footer;
