"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Infoblock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
// eslint-disable-next-line complexity
const Infoblock = ({ overline, title, content, ctaPrimary, ctaSecondary, inverse = false, image, altText = "info image", imageShadow, aspectRatio = "4/3", }) => {
    const imagePadding = imageShadow ? 5 : 0;
    return ((0, jsx_runtime_1.jsx)(material_1.Box, { children: (0, jsx_runtime_1.jsx)(material_1.Container, Object.assign({ maxWidth: "xl" }, { children: (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: {
                    display: "grid",
                    columnGap: 3,
                    rowGap: 5,
                    gridTemplateColumns: {
                        xs: "repeat(6, minmax(0, 1fr))",
                        md: "repeat(12, minmax(0, 1fr))",
                    },
                    py: {
                        xs: 4,
                        sm: 4,
                        md: 8,
                    },
                } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ gridColumn: {
                            xs: "span 6",
                            md: inverse ? "7 / span 5" : "2 / span 5",
                        }, gridRow: {
                            xs: "auto",
                            md: 1,
                        }, my: "auto" }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: 4 }, { children: [(0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: 2 }, { children: [overline && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "overline", color: "text.secondary" }, { children: overline }))), (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h4", color: "text.primary" }, { children: title })), (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [content && typeof content === "string" && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "body2" }, { children: content }))), content && typeof content !== "string" && content] })] })), (ctaPrimary || ctaSecondary) && ((0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ direction: { xs: "column", sm: "row" }, spacing: 2 }, { children: [ctaPrimary && ((0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ "aria-label": ctaPrimary.title, variant: "contained", onClick: ctaPrimary.onClick, component: "a" }, { children: ctaPrimary.label }))), ctaSecondary && ((0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ "aria-label": ctaSecondary.title, variant: "outlined", onClick: ctaSecondary.onClick, component: "a" }, { children: ctaSecondary.label })))] })))] })) })), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ gridColumn: {
                            xs: "span 6",
                            md: inverse ? "2 / span 5" : "7 / span 5",
                        }, gridRow: {
                            xs: "auto",
                            md: 1,
                        }, my: "auto" }, { children: (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: {
                                display: "grid",
                                position: "relative",
                                width: {
                                    xs: "100%",
                                    md: aspectRatio === "4/3" ? "auto" : "75%",
                                    lg: aspectRatio === "4/3" ? "auto" : "50%",
                                },
                                mx: "auto",
                                "&:before": {
                                    display: "block",
                                    content: '""',
                                    paddingTop: {
                                        xs: aspectRatio === "4/3" ? "75%" : "100%",
                                        md: aspectRatio === "4/3" ? "75%" : "177.75%",
                                    },
                                },
                            } }, { children: [imageShadow && ((0, jsx_runtime_1.jsx)(material_1.Box, { sx: {
                                        display: { xs: "none", md: "block" },
                                        boxSizing: "border-box",
                                        width: "100%",
                                        height: aspectRatio === "4/3" ? "auto" : "100%",
                                        objectFit: "cover",
                                        objectPosition: "50%",
                                        gridArea: "1 / 1 / 2 / 2",
                                        position: "absolute",
                                        zIndex: 3,
                                        paddingRight: inverse ? 0 : 5,
                                        paddingLeft: inverse ? 5 : 0,
                                        paddingTop: 5,
                                    } })), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
                                        boxSizing: "border-box",
                                        width: "100%",
                                        height: aspectRatio === "4/3" ? "auto" : "100%",
                                        gridArea: "1 / 1 / 2 / 2",
                                        position: "absolute",
                                        zIndex: 3,
                                        paddingBottom: 5,
                                        paddingLeft: {
                                            xs: 0,
                                            md: inverse ? 0 : imagePadding,
                                        },
                                        paddingRight: {
                                            xs: 0,
                                            md: inverse ? imagePadding : 0,
                                        },
                                    } }, { children: (0, jsx_runtime_1.jsx)(material_1.Box, { sx: {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            objectPosition: "50%",
                                        }, component: "img", alt: altText, src: image }) }))] })) }))] })) })) }));
};
exports.Infoblock = Infoblock;
