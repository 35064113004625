"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnvironmentBanner = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const EnvironmentBanner = ({ bgColor, message, icon, }) => ((0, jsx_runtime_1.jsx)(material_1.Alert, Object.assign({ sx: {
        borderLeft: "none",
        borderRadius: 0,
        alignItems: "center",
        justifyContent: "center",
        bgcolor: `${bgColor}.extraLight`,
        "&.MuiPaper-root": {
            px: { xs: 1, sm: 3 },
            py: 1,
        },
    }, icon: icon !== null && icon !== void 0 ? icon : false }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "body2", sx: { maxWidth: 480, textAlign: "center", wordBreak: "break-word" } }, { children: message })) })));
exports.EnvironmentBanner = EnvironmentBanner;
