"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOSAgreement = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function TOSAgreement({ productName, description, children, onConfirm, sx, confirmBtnDisabled, confirmBtnLabel = "Accedi", }) {
    const isDescriptionComponentAString = typeof description === "string";
    return ((0, jsx_runtime_1.jsx)(material_1.Stack, Object.assign({ alignItems: "center", justifyContent: "center", sx: Object.assign({ py: { xs: 4, lg: 16 }, backgroundColor: "#FAFAFA" }, sx) }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ sx: { maxWidth: 680, mx: "auto", px: { xs: 3, lg: 4 } }, spacing: { xs: 4, lg: 8 } }, { children: [(0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ sx: { textAlign: "center" }, spacing: 1 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h3" }, { children: productName })), (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ component: isDescriptionComponentAString ? "p" : "span" }, { children: description }))] })), children && (0, jsx_runtime_1.jsx)(material_1.Box, { children: children }), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: { textAlign: "center" } }, { children: (0, jsx_runtime_1.jsx)(material_1.Button, Object.assign({ onClick: onConfirm, variant: "contained", disabled: confirmBtnDisabled }, { children: confirmBtnLabel })) }))] })) })));
}
exports.TOSAgreement = TOSAgreement;
