"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotificationDot = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const _theme_1 = require("../../theme/index");
const TimelineNotificationDot = (props) => {
    const { children, size } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.TimelineDot, Object.assign({}, props, { sx: Object.assign({ my: _theme_1.theme.spacing(0.5), padding: size === "small" ? "1px" : "4px", alignSelf: "center" }, props.sx) }, { children: children })));
};
exports.TimelineNotificationDot = TimelineNotificationDot;
