"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyAccountItemButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const PartyAvatar_1 = require("../PartyAvatar");
const _theme_1 = require("../../theme/index");
const PartyAccountItemButton = ({ partyName, partyRole, image, selectedItem, action, disabled, endSlot, maxCharactersNumberMultiLine = 50, parentPartyName, }) => {
    const maxCharacter = partyName && partyName.length > maxCharactersNumberMultiLine;
    const truncatedText = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        width: "100%",
        whiteSpace: "normal",
    };
    return ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: Object.assign(Object.assign({ p: 1.5, width: "100%", backgroundColor: "background.paper", color: "text.primary", transitionProperty: "background-color", transitionDuration: `${_theme_1.theme.transitions.duration.short}ms`, userSelect: "none", boxSizing: "border-box" }, (!disabled && {
            cursor: "pointer",
            "&:hover": {
                backgroundColor: _theme_1.theme.palette.action.hover,
            },
        })), (selectedItem && {
            boxShadow: `inset 2px 0 0 0 ${_theme_1.theme.palette.primary.main}`,
            backgroundColor: _theme_1.theme.palette.primaryAction.selected,
            color: _theme_1.theme.palette.primary.main,
            "&:hover": {
                backgroundColor: _theme_1.theme.palette.primaryAction.hover,
            },
        })), role: "button", tabIndex: 0, onClick: action }, { children: (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: { display: "flex", flexDirection: "row" } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: Object.assign(Object.assign({}, (disabled && {
                        opacity: _theme_1.theme.palette.action.disabledOpacity,
                    })), { display: "flex", alignItems: "center" }) }, { children: (0, jsx_runtime_1.jsx)(PartyAvatar_1.PartyAvatar, { customAlt: partyName, customSrc: image }) })), (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: Object.assign({ ml: 1.25, alignSelf: "center", userSelect: "text" }, (disabled && {
                        opacity: _theme_1.theme.palette.action.disabledOpacity,
                        userSelect: "none",
                    })) }, { children: [parentPartyName && ((0, jsx_runtime_1.jsx)(material_1.Tooltip, Object.assign({ arrow: true, title: maxCharacter ? parentPartyName : "" }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "caption", component: "h6", color: "inherit", sx: Object.assign({ fontWeight: _theme_1.theme.typography.fontWeightMedium, lineHeight: 1.25 }, (maxCharacter && Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 1 }))) }, { children: parentPartyName })) }))), partyName && ((0, jsx_runtime_1.jsx)(material_1.Tooltip, Object.assign({ arrow: true, title: maxCharacter ? partyName : "" }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "body1", component: "h6", color: "inherit", sx: Object.assign({ fontWeight: _theme_1.theme.typography.fontWeightBold, lineHeight: 1.25 }, (maxCharacter && Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 2 }))) }, { children: partyName })) }))), partyRole && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "caption", color: "inherit", sx: Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 1 }) }, { children: partyRole })))] })), endSlot && ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: { display: "flex", alignItems: "center", ml: "auto", pl: 1.25 } }, { children: endSlot })))] })) })));
};
exports.PartyAccountItemButton = PartyAccountItemButton;
