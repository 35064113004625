"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotificationItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const TimelineNotificationItem = (props) => {
    const { children } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.TimelineItem, Object.assign({ sx: Object.assign({ 
            /* Remove extra space because there's opposite content, but the relative
              prop is not exposed by the component's API */
            "&:before": {
                display: "none",
            } }, props.sx) }, { children: children })));
};
exports.TimelineNotificationItem = TimelineNotificationItem;
