"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Showcase = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const Showcase = ({ title, items }) => ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ bgcolor: "#FAFAFA" }, { children: (0, jsx_runtime_1.jsx)(material_1.Container, Object.assign({ maxWidth: "xl", sx: {
            py: {
                xs: 4,
                sm: 4,
                md: 8,
            },
        } }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ alignContent: "center", textAlign: "center", spacing: 8 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h4" }, { children: title })), (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
                        display: "grid",
                        gap: 3,
                        gridTemplateColumns: "repeat(12, 1fr)",
                    } }, { children: (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ gridColumn: "2 / span 10" }, { children: (0, jsx_runtime_1.jsx)(material_1.Stack, Object.assign({ direction: { xs: "column", md: "row" }, alignContent: "center", justifyContent: "center", spacing: { xs: 6, md: 4 } }, { children: items.map((item, index) => ((0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ alignContent: "center", justifyContent: "flex-start", spacing: { xs: 1, md: 4 }, sx: {
                                    flex: 1,
                                } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ mx: "auto", sx: {
                                            color: "primary.dark",
                                            svg: {
                                                height: "64px",
                                                width: "64px",
                                            },
                                        } }, { children: item.icon })), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: 1 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "h6" }, { children: item.title })), (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [item.subtitle && typeof item.subtitle === "string" && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "body2" }, { children: item.subtitle }))), item.subtitle &&
                                                        typeof item.subtitle !== "string" &&
                                                        item.subtitle] })] }))] }), index))) })) })) }))] })) })) })));
exports.Showcase = Showcase;
