"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotificationOppositeContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const material_1 = require("@mui/material");
const _theme_1 = require("../../theme/index");
const TimelineNotificationOppositeContent = (props) => {
    const { children } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.TimelineOppositeContent, Object.assign({ sx: Object.assign({ display: "flex", alignItems: "center", justifyContent: "end", flex: "15% 0", p: "0", pr: _theme_1.theme.spacing(2.5) }, props.sx) }, { children: (0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
            } }, { children: children })) })));
};
exports.TimelineNotificationOppositeContent = TimelineNotificationOppositeContent;
