"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterPostLogin = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const LangSwitch_1 = require("../LangSwitch");
const LogoPagoPACompany_1 = require("../../assets/LogoPagoPACompany");
const ts_utils_1 = require("../../utils/ts-utils");
const FooterPostLogin = (_a) => {
    var _b, _c;
    var { companyLink, links, onExit } = _a, langProps = tslib_1.__rest(_a, ["companyLink", "links", "onExit"]);
    return ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
            borderTop: 1,
            borderColor: "divider",
            backgroundColor: "background.paper",
        } }, { children: (0, jsx_runtime_1.jsx)(material_1.Container, Object.assign({ maxWidth: false, sx: { py: { xs: 3, md: 2 } } }, { children: (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: { xs: 4, md: 3 }, direction: { xs: "column", md: "row" }, justifyContent: "space-between", sx: { alignItems: "center" } }, { children: [companyLink && ((0, jsx_runtime_1.jsx)(material_1.Link, Object.assign({ "aria-label": companyLink === null || companyLink === void 0 ? void 0 : companyLink.ariaLabel, href: (_b = companyLink === null || companyLink === void 0 ? void 0 : companyLink.href) !== null && _b !== void 0 ? _b : ts_utils_1.hrefNoOp, onClick: (0, ts_utils_1.wrapHandleExitAction)((_c = companyLink.href) !== null && _c !== void 0 ? _c : ts_utils_1.hrefNoOp, companyLink.onClick, onExit), sx: { display: "inline-flex" } }, { children: (0, jsx_runtime_1.jsx)(LogoPagoPACompany_1.LogoPagoPACompany, {}) }))), (0, jsx_runtime_1.jsxs)(material_1.Stack, Object.assign({ spacing: { xs: 1, md: 3 }, direction: { xs: "column", md: "row" }, sx: { alignItems: "center" } }, { children: [links.map(({ href = ts_utils_1.hrefNoOp, label, ariaLabel, onClick }, i) => ((0, jsx_runtime_1.jsx)(material_1.Link, Object.assign({ "aria-label": ariaLabel, href: href, onClick: (0, ts_utils_1.wrapHandleExitAction)(href, onClick, onExit), underline: "none", color: "text.primary", sx: { display: "inline-block" }, variant: "subtitle2" }, { children: label }), i))), (0, jsx_runtime_1.jsx)(LangSwitch_1.LangSwitch, Object.assign({}, langProps))] }))] })) })) })));
};
exports.FooterPostLogin = FooterPostLogin;
