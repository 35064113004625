"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductArrayType = exports.ProductType = void 0;
const tslib_1 = require("tslib");
const t = tslib_1.__importStar(require("io-ts"));
const ts_utils_1 = require("../../utils/ts-utils");
var LinkType;
(function (LinkType) {
    LinkType["internal"] = "internal";
    LinkType["external"] = "external";
})(LinkType || (LinkType = {}));
exports.ProductType = t.interface({
    label: t.string,
    href: t.string,
    ariaLabel: t.string,
    linkType: (0, ts_utils_1.createEnumType)(LinkType, "LinkTypeIoTs"),
});
exports.ProductArrayType = t.readonlyArray(exports.ProductType, "array of ProductType");
