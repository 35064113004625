"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateRandomID = exports.getColorStyle = exports.getContainerStyle = exports.getStatus = exports.verifyAccept = exports.truncateFileName = void 0;
const system_1 = require("@mui/system");
const _theme_1 = require("../../theme/index");
const SingleFileInput_1 = require("./SingleFileInput");
/**
 * Truncate file name string if it is longer than 30 characters.
 * Keeps the file extension.
 *
 * @param fileName
 * @returns truncated file name
 */
const truncateFileName = (fileName) => {
    const splittedFileName = fileName.split(".");
    const fileExtension = splittedFileName[1];
    const truncatedFileName = splittedFileName[0];
    if (truncatedFileName.length >= 30) {
        return `${truncatedFileName}... .${fileExtension !== null && fileExtension !== void 0 ? fileExtension : ""}`;
    }
    return fileName;
};
exports.truncateFileName = truncateFileName;
/**
 * Check if a mime type matches the set given in accept
 *
 * @link https://stackoverflow.com/a/66489392
 *
 * @param type the mime type to test, ex image/png
 * @param accept the mime types to accept, ex audio/*,video/*,image/png
 * @returns true if the mime is accepted, false otherwise
 */
function verifyAccept(type, accept) {
    if (!accept) {
        return true;
    }
    return accept.includes(type) || accept.includes(type.split("/")[0] + "/*");
}
exports.verifyAccept = verifyAccept;
/**
 * Returns the current status of the SingleFileInput based on his current state
 *
 * @param file the current file
 * @param isLoading if the component is currently loading
 * @param isFileRejected if the file is rejected
 * @returns the current SingleFileInput status
 */
function getStatus(file, isLoading, error, isFileRejected) {
    if (error) {
        return SingleFileInput_1.UploadStatus.ERROR;
    }
    if (isLoading) {
        return SingleFileInput_1.UploadStatus.LOADING;
    }
    if (isFileRejected) {
        return SingleFileInput_1.UploadStatus.REJECTED;
    }
    if (!file) {
        return SingleFileInput_1.UploadStatus.IDLE;
    }
    return SingleFileInput_1.UploadStatus.SELECTED;
}
exports.getStatus = getStatus;
/**
 *
 * @param status the current status of the SingleFileInput
 * @returns the associated container styles
 */
function getContainerStyle(status) {
    switch (status) {
        case SingleFileInput_1.UploadStatus.IDLE:
            return {
                border: "1px dashed",
                borderColor: _theme_1.theme.palette.primary.main,
                backgroundColor: _theme_1.theme.palette.primaryAction.selected,
            };
        case SingleFileInput_1.UploadStatus.LOADING:
            return {
                border: "1px dashed",
                borderColor: _theme_1.theme.palette.primary.main,
                backgroundColor: _theme_1.theme.palette.primaryAction.selected,
            };
        case SingleFileInput_1.UploadStatus.REJECTED:
        case SingleFileInput_1.UploadStatus.ERROR:
            return {
                border: "1px dashed",
                borderColor: _theme_1.theme.palette.error.main,
                backgroundColor: (0, system_1.alpha)(_theme_1.theme.palette.error.main, 0.1),
            };
        case SingleFileInput_1.UploadStatus.SELECTED:
            return {
                border: "1px solid",
                borderColor: _theme_1.theme.palette.primary.main,
                backgroundColor: "white",
                px: 4,
            };
        default:
            return {};
    }
}
exports.getContainerStyle = getContainerStyle;
function getColorStyle(status) {
    switch (status) {
        case SingleFileInput_1.UploadStatus.IDLE:
            return "primary";
        case SingleFileInput_1.UploadStatus.ERROR:
            return "error";
        default:
            return "primary";
    }
}
exports.getColorStyle = getColorStyle;
function generateRandomID() {
    /* eslint-disable no-bitwise */
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const randomNum = typeof window !== "undefined"
            ? window.crypto.getRandomValues(new Uint8Array(1))[0] | 0
            : (Math.random() * 16) | 0;
        const v = c === "x" ? randomNum : (randomNum & 0x3) | 0x8;
        return v.toString(16);
    });
}
exports.generateRandomID = generateRandomID;
