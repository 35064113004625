"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Illustration = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
// Components
const material_1 = require("@mui/material");
const IllustrationSVG = (0, material_1.styled)(material_1.SvgIcon)(({ theme }) => ({
    fill: theme.palette.secondary.main,
}));
IllustrationSVG.defaultProps = {
    viewBox: "0 0 120 120",
    focusable: "false",
    "aria-hidden": "true",
};
const Illustration = (props) => {
    const { children, name, size = 80 } = props, rest = tslib_1.__rest(props, ["children", "name", "size"]);
    return ((0, jsx_runtime_1.jsx)(IllustrationSVG, Object.assign({ titleAccess: name, sx: { width: size, height: size } }, rest, { children: children })));
};
exports.Illustration = Illustration;
