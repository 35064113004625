"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllusRepository = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const Illustration_1 = require("../components/Illustration");
const IllusRepository = (_a) => {
    var { title = "Repository" } = _a, rest = tslib_1.__rest(_a, ["title"]);
    return ((0, jsx_runtime_1.jsxs)(Illustration_1.Illustration, Object.assign({ name: title }, rest, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M101.929 38.654a11.541 11.541 0 0 0-6.346-6.346c-1.735-4.314-5.98-7.19-10.638-7.19H54.47a18.215 18.215 0 0 0-2.283-1.987l-3.676-3.949C45.315 15.712 41.663 14 37.325 14H21.46C15.136 14 10 19.136 10 25.46v57.139c0 4.634 2.876 8.88 7.19 10.638a11.543 11.543 0 0 0 6.347 6.346c1.735 4.315 5.981 7.191 10.638 7.191h63.486c6.323 0 11.459-5.136 11.459-11.46V49.27c0-4.634-2.876-8.88-7.191-10.616Zm-80.47-20.773h15.866c2.58 0 4.772.799 6.803 2.465H27.83c-6.323 0-11.46 5.137-11.46 11.46V88.17c-1.552-1.438-2.465-3.447-2.465-5.57v-57.14c-.023-4.177 3.378-7.578 7.556-7.578Zm-1.21 13.948c0-4.178 3.402-7.58 7.58-7.58h15.865c2.215 0 4.155.594 5.913 1.804l.616.662H34.175c-6.323 0-11.46 5.137-11.46 11.46v56.363c-1.552-1.438-2.465-3.447-2.465-5.57v-57.14Zm6.347 6.346c0-4.177 3.402-7.579 7.58-7.579H50.04c3.219 0 5.867 1.256 8.332 3.927l6.14 6.574c.366.388.891.617 1.416.617h31.754c4.178 0 7.579 3.401 7.579 7.578v46.022c0 4.178-3.401 7.579-7.579 7.579H34.175c-4.177 0-7.579-3.401-7.579-7.579V38.175Zm34.562-6.711c-.091 0-.205 0-.297.023a17.511 17.511 0 0 0-2.967-2.489h27.074a7.57 7.57 0 0 1 5.57 2.466h-29.38Zm5.593 6.346-2.306-2.466h26.847a7.57 7.57 0 0 1 5.57 2.466h-30.11Z" }), (0, jsx_runtime_1.jsx)("path", { d: "M80.197 54.497h17.464c1.073 0 1.94-.867 1.94-1.94s-.867-1.94-1.94-1.94H80.197c-1.073 0-1.94.867-1.94 1.94s.867 1.94 1.94 1.94ZM80.197 60.844h17.464c1.073 0 1.94-.868 1.94-1.94 0-1.074-.867-1.941-1.94-1.941H80.197c-1.073 0-1.94.867-1.94 1.94s.867 1.94 1.94 1.94Z" })] })));
};
exports.IllusRepository = IllusRepository;
