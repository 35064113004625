// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { WalletId } from "./WalletId";

import { SessionInputData } from "./SessionInputData";

import { SessionWalletCreateResponse } from "./SessionWalletCreateResponse";

import { ProblemJson } from "./ProblemJson";

import { OrderId } from "./OrderId";

import { WalletVerifyRequestsResponse } from "./WalletVerifyRequestsResponse";

import { SessionWalletRetrieveResponse } from "./SessionWalletRetrieveResponse";

import { BundleOption } from "./BundleOption";

/****************************************************************
 * createSessionWallet
 */

// Request type definition
export type CreateSessionWalletT = r.IPostApiRequestType<
  {
    readonly bearerAuth: string;
    readonly walletId: WalletId;
    readonly body: SessionInputData;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, SessionWalletCreateResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<502, ProblemJson, never>
  | r.IResponseType<504, undefined, never>
>;

export const createSessionWalletDefaultResponses = {
  200: SessionWalletCreateResponse,
  400: ProblemJson,
  401: ProblemJson,
  404: ProblemJson,
  500: ProblemJson,
  502: ProblemJson,
  504: t.undefined
};

export type CreateSessionWalletResponsesT<
  A0 = SessionWalletCreateResponse,
  C0 = SessionWalletCreateResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
  502: t.Type<A5, C5>;
  504: t.Type<A6, C6>;
};

export function createSessionWalletDecoder<
  A0 = SessionWalletCreateResponse,
  C0 = SessionWalletCreateResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined
>(
  overrideTypes:
    | Partial<
        CreateSessionWalletResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<500, A4, never>
  | r.IResponseType<502, A5, never>
  | r.IResponseType<504, A6, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createSessionWalletDefaultResponses as unknown) as CreateSessionWalletResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A4, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A5, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A6, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d404
        ),
        d500
      ),
      d502
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const createSessionWalletDefaultDecoder = () =>
  createSessionWalletDecoder();

/****************************************************************
 * postWalletValidations
 */

// Request type definition
export type PostWalletValidationsT = r.IPostApiRequestType<
  {
    readonly bearerAuth: string;
    readonly walletId: WalletId;
    readonly orderId: OrderId;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletVerifyRequestsResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, undefined, never>
  | r.IResponseType<504, undefined, never>
>;

export const postWalletValidationsDefaultResponses = {
  200: WalletVerifyRequestsResponse,
  400: ProblemJson,
  401: ProblemJson,
  404: ProblemJson,
  500: t.undefined,
  504: t.undefined
};

export type PostWalletValidationsResponsesT<
  A0 = WalletVerifyRequestsResponse,
  C0 = WalletVerifyRequestsResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = undefined,
  C5 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
  504: t.Type<A5, C5>;
};

export function postWalletValidationsDecoder<
  A0 = WalletVerifyRequestsResponse,
  C0 = WalletVerifyRequestsResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = undefined,
  C5 = undefined
>(
  overrideTypes:
    | Partial<
        PostWalletValidationsResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<500, A4, never>
  | r.IResponseType<504, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((postWalletValidationsDefaultResponses as unknown) as PostWalletValidationsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A4, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
        d404
      ),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const postWalletValidationsDefaultDecoder = () =>
  postWalletValidationsDecoder();

/****************************************************************
 * getSessionWallet
 */

// Request type definition
export type GetSessionWalletT = r.IGetApiRequestType<
  {
    readonly bearerAuth: string;
    readonly walletId: WalletId;
    readonly orderId: OrderId;
  },
  "Authorization",
  never,
  | r.IResponseType<200, SessionWalletRetrieveResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<502, ProblemJson, never>
  | r.IResponseType<504, undefined, never>
>;

export const getSessionWalletDefaultResponses = {
  200: SessionWalletRetrieveResponse,
  400: ProblemJson,
  401: ProblemJson,
  404: ProblemJson,
  500: ProblemJson,
  502: ProblemJson,
  504: t.undefined
};

export type GetSessionWalletResponsesT<
  A0 = SessionWalletRetrieveResponse,
  C0 = SessionWalletRetrieveResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
  502: t.Type<A5, C5>;
  504: t.Type<A6, C6>;
};

export function getSessionWalletDecoder<
  A0 = SessionWalletRetrieveResponse,
  C0 = SessionWalletRetrieveResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = undefined,
  C6 = undefined
>(
  overrideTypes:
    | Partial<
        GetSessionWalletResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<500, A4, never>
  | r.IResponseType<502, A5, never>
  | r.IResponseType<504, A6, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getSessionWalletDefaultResponses as unknown) as GetSessionWalletResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A4, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A5, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A6, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d404
        ),
        d500
      ),
      d502
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const getSessionWalletDefaultDecoder = () => getSessionWalletDecoder();

/****************************************************************
 * getPspsForWallet
 */

// Request type definition
export type GetPspsForWalletT = r.IGetApiRequestType<
  { readonly walletId: string; readonly bearerAuth: string },
  "Authorization",
  never,
  r.IResponseType<200, BundleOption, never>
>;

export const getPspsForWalletDefaultResponses = {
  200: BundleOption
};

export type GetPspsForWalletResponsesT<A0 = BundleOption, C0 = BundleOption> = {
  200: t.Type<A0, C0>;
};

export function getPspsForWalletDecoder<A0 = BundleOption, C0 = BundleOption>(
  overrideTypes:
    | Partial<GetPspsForWalletResponsesT<A0, C0>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<r.IResponseType<200, A0, never>> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspsForWalletDefaultResponses as unknown) as GetPspsForWalletResponsesT<
      A0,
      C0
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  return d200;
}

// Decodes the success response with the type defined in the specs
export const getPspsForWalletDefaultDecoder = () => getPspsForWalletDecoder();
