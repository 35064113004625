"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyAccountItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const material_2 = require("@mui/material");
const PartyAvatar_1 = require("../PartyAvatar");
const _theme_1 = require("../../theme/index");
const PartyAccountItem = ({ partyName, parentPartyName, partyRole, image, noWrap = true, containerSx, infoContainerSx, maxCharactersNumberMultiLine = 50, }) => {
    const containerStyle = (0, react_1.useMemo)(() => (Object.assign({ userSelect: "none" }, containerSx)), []);
    const maxCharacter = partyName && partyName.length > maxCharactersNumberMultiLine;
    const truncatedText = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        width: "100%",
        whiteSpace: "normal",
    };
    return ((0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: containerStyle }, { children: (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: { display: "flex", flexDirection: "row" } }, { children: [(0, jsx_runtime_1.jsx)(material_1.Box, Object.assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                    } }, { children: (0, jsx_runtime_1.jsx)(PartyAvatar_1.PartyAvatar, { customAlt: partyName, customSrc: image }) })), (0, jsx_runtime_1.jsxs)(material_1.Box, Object.assign({ sx: Object.assign({ ml: 1.25, alignSelf: "center", userSelect: "text" }, infoContainerSx) }, { children: [parentPartyName && ((0, jsx_runtime_1.jsx)(material_2.Tooltip, Object.assign({ arrow: true, title: maxCharacter ? parentPartyName : "" }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "caption", component: "h6", color: "colorTextPrimary", sx: Object.assign({ fontWeight: _theme_1.theme.typography.fontWeightMedium, lineHeight: 1.25 }, (maxCharacter && Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 1 }))) }, { children: parentPartyName })) }))), partyName && ((0, jsx_runtime_1.jsx)(material_2.Tooltip, Object.assign({ arrow: true, title: maxCharacter ? partyName : "" }, { children: (0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ textAlign: "start", variant: "body1", component: "h6", sx: Object.assign(Object.assign({ fontWeight: _theme_1.theme.typography.fontWeightBold, lineHeight: 1.25 }, (noWrap && {
                                    whiteSpace: "nowrap",
                                })), (maxCharacter && Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 2 }))) }, { children: partyName })) }))), partyRole && ((0, jsx_runtime_1.jsx)(material_1.Typography, Object.assign({ variant: "caption", sx: Object.assign(Object.assign({}, truncatedText), { WebkitLineClamp: 1 }) }, { children: partyRole })))] }))] })) })));
};
exports.PartyAccountItem = PartyAccountItem;
