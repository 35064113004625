"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FundedByNextGenerationEU = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
// Components
const material_1 = require("@mui/material");
const _theme_1 = require("../../theme/index");
// Partials
const partials_1 = require("../FundedByNextGenerationEU/partials");
const StyledSvg = (0, material_1.styled)("svg")({
    display: "inline-block",
    userSelect: "none",
});
const colorMap = {
    dark: _theme_1.theme.palette.text.primary,
    light: _theme_1.theme.palette.common.white,
    pantone: _theme_1.theme.palette.europeanUnion.main,
};
const variantMap = {
    outline: (0, jsx_runtime_1.jsx)(partials_1.SvgOutline, {}),
    filled: (0, jsx_runtime_1.jsx)(partials_1.SvgFilled, {}),
};
const variantColorMap = {
    dark: (0, jsx_runtime_1.jsx)(partials_1.SvgColorDark, {}),
    light: (0, jsx_runtime_1.jsx)(partials_1.SvgColorLight, {}),
    pantone: (0, jsx_runtime_1.jsx)(partials_1.SvgColorDark, {}),
};
const FundedByNextGenerationEU = ({ title = "Finanziato dall'Unione Europea · NextGenerationEU", size = 200, color = "dark", variant = "outline", }) => ((0, jsx_runtime_1.jsxs)(StyledSvg, Object.assign({ viewBox: "0 0 1174 270", focusable: "false", role: "img", "aria-labelledby": "logo-next-genEU-titleID", sx: {
        width: size,
        /* If the color variant is set,
        the following property is ignored */
        fill: colorMap[color],
        maxWidth: "100%",
    } }, { children: [variant === "color" ? variantColorMap[color] : variantMap[variant], (0, jsx_runtime_1.jsx)("title", Object.assign({ id: "logo-next-genEU-titleID" }, { children: title }))] })));
exports.FundedByNextGenerationEU = FundedByNextGenerationEU;
