"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotificationSeparator = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const TimelineNotificationSeparator = (props) => {
    const { children } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.TimelineSeparator, Object.assign({ sx: Object.assign({ minWidth: "12px" }, props.sx) }, { children: children })));
};
exports.TimelineNotificationSeparator = TimelineNotificationSeparator;
