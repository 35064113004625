"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IllusLogin = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const Illustration_1 = require("../components/Illustration");
const IllusLogin = (_a) => {
    var { title = "Login" } = _a, rest = tslib_1.__rest(_a, ["title"]);
    return ((0, jsx_runtime_1.jsxs)(Illustration_1.Illustration, Object.assign({ name: title }, rest, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M110.625 5.625H9.375A5.632 5.632 0 0 0 3.75 11.25v97.5a5.634 5.634 0 0 0 5.625 5.625h101.25a5.637 5.637 0 0 0 3.976-1.649 5.637 5.637 0 0 0 1.649-3.976v-97.5a5.634 5.634 0 0 0-5.625-5.625ZM9.375 9.375h101.25a1.876 1.876 0 0 1 1.875 1.875v9.375H7.5V11.25a1.877 1.877 0 0 1 1.875-1.875Zm101.25 101.25H9.375A1.877 1.877 0 0 1 7.5 108.75V24.375h105v84.375a1.876 1.876 0 0 1-1.875 1.875Z" }), (0, jsx_runtime_1.jsx)("path", { d: "M28.125 16.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM20.625 16.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM13.125 16.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM82.5 76.875h-45a1.875 1.875 0 0 0-1.875 1.875v7.5a1.875 1.875 0 0 0 1.875 1.875h45a1.875 1.875 0 0 0 1.875-1.875v-7.5a1.875 1.875 0 0 0-1.875-1.875Zm-43.125 3.75h3.75v3.75h-3.75v-3.75Zm41.25 3.75h-33.75v-3.75h33.75v3.75ZM82.5 91.875h-45a1.875 1.875 0 0 0-1.875 1.875v7.5a1.876 1.876 0 0 0 1.875 1.875h45a1.877 1.877 0 0 0 1.875-1.875v-7.5a1.875 1.875 0 0 0-1.875-1.875Zm-43.125 3.75h3.75v3.75h-3.75v-3.75Zm41.25 3.75h-33.75v-3.75h33.75v3.75ZM60 73.125a20.629 20.629 0 1 0 .007-41.257A20.629 20.629 0 0 0 60 73.125Zm-11.983-8.75c2.104-6.022 6.816-10 11.983-10 5.167 0 9.88 3.978 11.982 10a16.857 16.857 0 0 1-23.965 0Zm8.233-17.5a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM60 35.625a16.869 16.869 0 0 1 14.616 25.301c-2.018-4.263-5.222-7.483-8.979-9.112a7.5 7.5 0 1 0-11.273 0c-3.757 1.63-6.961 4.85-8.98 9.112A16.87 16.87 0 0 1 60 35.625Z" })] })));
};
exports.IllusLogin = IllusLogin;
