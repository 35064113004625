"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineNotification = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lab_1 = require("@mui/lab");
const _theme_1 = require("../../theme/index");
const TimelineNotification = (props) => {
    const { children } = props;
    return ((0, jsx_runtime_1.jsx)(lab_1.Timeline, Object.assign({ sx: Object.assign({ px: "0", py: _theme_1.theme.spacing(2) }, props.sx) }, { children: children })));
};
exports.TimelineNotification = TimelineNotification;
