// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { AbiListResponse } from "./AbiListResponse";

import { BancomatCardsRequest } from "./BancomatCardsRequest";

import { WalletV2ListResponse } from "./WalletV2ListResponse";

import { RestPanResponse } from "./RestPanResponse";

import { BPayRequest } from "./BPayRequest";

import { RestBPayResponse } from "./RestBPayResponse";

import { PayRequest } from "./PayRequest";

import { TransactionResponse } from "./TransactionResponse";

import { PaymentResponse } from "./PaymentResponse";

import { PspListResponseCD } from "./PspListResponseCD";

import { PspResponse } from "./PspResponse";

import { ResourcesResponse } from "./ResourcesResponse";

import { SatispayRequest } from "./SatispayRequest";

import { WalletV2Response } from "./WalletV2Response";

import { RestSatispayResponse } from "./RestSatispayResponse";

import { TransactionListResponse } from "./TransactionListResponse";

import { TransactionStatusResponse } from "./TransactionStatusResponse";

import { ResumeRequest } from "./ResumeRequest";

import { UserResponse } from "./UserResponse";

import { SendOtpRequest } from "./SendOtpRequest";

import { SessionResponse } from "./SessionResponse";

import { VerifyOtpRequest } from "./VerifyOtpRequest";

import { WalletListResponse } from "./WalletListResponse";

import { WalletRequest } from "./WalletRequest";

import { WalletResponse } from "./WalletResponse";

import { CheckCardBinRequest } from "./CheckCardBinRequest";

import { CheckCardBinResponse } from "./CheckCardBinResponse";

import { WalletPaymentStatusRequest } from "./WalletPaymentStatusRequest";

import { DeletedWalletsResponse } from "./DeletedWalletsResponse";

import { PspDataListResponse } from "./PspDataListResponse";

import { PaypalPspListResponse } from "./PaypalPspListResponse";

/****************************************************************
 * getAbiListUsingGET
 */

// Request type definition
export type GetAbiListUsingGETT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly size?: number;
    readonly start?: number;
    readonly abiQuery?: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, AbiListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getAbiListUsingGETDefaultResponses = {
  200: AbiListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetAbiListUsingGETResponsesT<
  A0 = AbiListResponse,
  C0 = AbiListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getAbiListUsingGETDecoder<
  A0 = AbiListResponse,
  C0 = AbiListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetAbiListUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getAbiListUsingGETDefaultResponses as unknown) as GetAbiListUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getAbiListUsingGETDefaultDecoder = () =>
  getAbiListUsingGETDecoder();

/****************************************************************
 * addWalletsBancomatCardUsingPOST
 */

// Request type definition
export type AddWalletsBancomatCardUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly bancomatCardsRequest: BancomatCardsRequest;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletV2ListResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const addWalletsBancomatCardUsingPOSTDefaultResponses = {
  200: WalletV2ListResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type AddWalletsBancomatCardUsingPOSTResponsesT<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function addWalletsBancomatCardUsingPOSTDecoder<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        AddWalletsBancomatCardUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((addWalletsBancomatCardUsingPOSTDefaultResponses as unknown) as AddWalletsBancomatCardUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const addWalletsBancomatCardUsingPOSTDefaultDecoder = () =>
  addWalletsBancomatCardUsingPOSTDecoder();

/****************************************************************
 * getPansUsingGET
 */

// Request type definition
export type GetPansUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly abi?: string },
  "Authorization",
  never,
  | r.IResponseType<200, RestPanResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPansUsingGETDefaultResponses = {
  200: RestPanResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPansUsingGETResponsesT<
  A0 = RestPanResponse,
  C0 = RestPanResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPansUsingGETDecoder<
  A0 = RestPanResponse,
  C0 = RestPanResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPansUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPansUsingGETDefaultResponses as unknown) as GetPansUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPansUsingGETDefaultDecoder = () => getPansUsingGETDecoder();

/****************************************************************
 * addWalletsBPayUsingPOST
 */

// Request type definition
export type AddWalletsBPayUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly bPayRequest: BPayRequest },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletV2ListResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const addWalletsBPayUsingPOSTDefaultResponses = {
  200: WalletV2ListResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type AddWalletsBPayUsingPOSTResponsesT<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function addWalletsBPayUsingPOSTDecoder<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        AddWalletsBPayUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((addWalletsBPayUsingPOSTDefaultResponses as unknown) as AddWalletsBPayUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const addWalletsBPayUsingPOSTDefaultDecoder = () =>
  addWalletsBPayUsingPOSTDecoder();

/****************************************************************
 * getBpayListUsingGET
 */

// Request type definition
export type GetBpayListUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly abi?: string },
  "Authorization",
  never,
  | r.IResponseType<200, RestBPayResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getBpayListUsingGETDefaultResponses = {
  200: RestBPayResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetBpayListUsingGETResponsesT<
  A0 = RestBPayResponse,
  C0 = RestBPayResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getBpayListUsingGETDecoder<
  A0 = RestBPayResponse,
  C0 = RestBPayResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetBpayListUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getBpayListUsingGETDefaultResponses as unknown) as GetBpayListUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getBpayListUsingGETDefaultDecoder = () =>
  getBpayListUsingGETDecoder();

/****************************************************************
 * payCreditCardVerificationUsingPOST
 */

// Request type definition
export type PayCreditCardVerificationUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly payRequest: PayRequest;
    readonly language?: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, TransactionResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const payCreditCardVerificationUsingPOSTDefaultResponses = {
  200: TransactionResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type PayCreditCardVerificationUsingPOSTResponsesT<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function payCreditCardVerificationUsingPOSTDecoder<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        PayCreditCardVerificationUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((payCreditCardVerificationUsingPOSTDefaultResponses as unknown) as PayCreditCardVerificationUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const payCreditCardVerificationUsingPOSTDefaultDecoder = () =>
  payCreditCardVerificationUsingPOSTDecoder();

/****************************************************************
 * deletePaymentUsingDELETE
 */

// Request type definition
export type DeletePaymentUsingDELETET = r.IDeleteApiRequestType<
  {
    readonly Bearer: string;
    readonly id: string;
    readonly showWallet?: boolean;
  },
  "Authorization",
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<204, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
>;

export const deletePaymentUsingDELETEDefaultResponses = {
  200: t.undefined,
  204: t.undefined,
  401: t.undefined,
  403: t.undefined
};

export type DeletePaymentUsingDELETEResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  204: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
};

export function deletePaymentUsingDELETEDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<
        DeletePaymentUsingDELETEResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<204, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deletePaymentUsingDELETEDefaultResponses as unknown) as DeletePaymentUsingDELETEResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d204 = (type[204].name === "undefined"
    ? r.constantResponseDecoder<undefined, 204, never>(204, undefined)
    : r.ioResponseDecoder<
        204,
        typeof type[204]["_A"],
        typeof type[204]["_O"],
        never
      >(204, type[204])) as r.ResponseDecoder<r.IResponseType<204, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d204), d401),
    d403
  );
}

// Decodes the success response with the type defined in the specs
export const deletePaymentUsingDELETEDefaultDecoder = () =>
  deletePaymentUsingDELETEDecoder();

/****************************************************************
 * checkPaymentUsingGET
 */

// Request type definition
export type CheckPaymentUsingGETT = r.IGetApiRequestType<
  { readonly id: string },
  never,
  never,
  | r.IResponseType<200, PaymentResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const checkPaymentUsingGETDefaultResponses = {
  200: PaymentResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type CheckPaymentUsingGETResponsesT<
  A0 = PaymentResponse,
  C0 = PaymentResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function checkPaymentUsingGETDecoder<
  A0 = PaymentResponse,
  C0 = PaymentResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<CheckPaymentUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((checkPaymentUsingGETDefaultResponses as unknown) as CheckPaymentUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const checkPaymentUsingGETDefaultDecoder = () =>
  checkPaymentUsingGETDecoder();

/****************************************************************
 * getPaymentUsingGET
 */

// Request type definition
export type GetPaymentUsingGETT = r.IGetApiRequestType<
  { readonly id: string },
  never,
  never,
  | r.IResponseType<200, PaymentResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPaymentUsingGETDefaultResponses = {
  200: PaymentResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPaymentUsingGETResponsesT<
  A0 = PaymentResponse,
  C0 = PaymentResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPaymentUsingGETDecoder<
  A0 = PaymentResponse,
  C0 = PaymentResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPaymentUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentUsingGETDefaultResponses as unknown) as GetPaymentUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentUsingGETDefaultDecoder = () =>
  getPaymentUsingGETDecoder();

/****************************************************************
 * deleteBySessionCookieExpiredUsingDELETE
 */

// Request type definition
export type DeleteBySessionCookieExpiredUsingDELETET = r.IDeleteApiRequestType<
  {
    readonly Bearer: string;
    readonly id: string;
    readonly showWallet?: boolean;
  },
  "Authorization",
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<204, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
>;

export const deleteBySessionCookieExpiredUsingDELETEDefaultResponses = {
  200: t.undefined,
  204: t.undefined,
  401: t.undefined,
  403: t.undefined
};

export type DeleteBySessionCookieExpiredUsingDELETEResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  204: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
};

export function deleteBySessionCookieExpiredUsingDELETEDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<
        DeleteBySessionCookieExpiredUsingDELETEResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<204, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteBySessionCookieExpiredUsingDELETEDefaultResponses as unknown) as DeleteBySessionCookieExpiredUsingDELETEResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d204 = (type[204].name === "undefined"
    ? r.constantResponseDecoder<undefined, 204, never>(204, undefined)
    : r.ioResponseDecoder<
        204,
        typeof type[204]["_A"],
        typeof type[204]["_O"],
        never
      >(204, type[204])) as r.ResponseDecoder<r.IResponseType<204, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d204), d401),
    d403
  );
}

// Decodes the success response with the type defined in the specs
export const deleteBySessionCookieExpiredUsingDELETEDefaultDecoder = () =>
  deleteBySessionCookieExpiredUsingDELETEDecoder();

/****************************************************************
 * paySslUsingPOST
 */

// Request type definition
export type PaySslUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly id: string;
    readonly payRequest: PayRequest;
    readonly language?: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, TransactionResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const paySslUsingPOSTDefaultResponses = {
  200: TransactionResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type PaySslUsingPOSTResponsesT<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function paySslUsingPOSTDecoder<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<PaySslUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((paySslUsingPOSTDefaultResponses as unknown) as PaySslUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const paySslUsingPOSTDefaultDecoder = () => paySslUsingPOSTDecoder();

/****************************************************************
 * getPspListUsingGET
 */

// Request type definition
export type GetPspListUsingGETT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly paymentType?: string;
    readonly paymentModel?: number;
    readonly idPayment: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, PspListResponseCD, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPspListUsingGETDefaultResponses = {
  200: PspListResponseCD,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPspListUsingGETResponsesT<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPspListUsingGETDecoder<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPspListUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspListUsingGETDefaultResponses as unknown) as GetPspListUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPspListUsingGETDefaultDecoder = () =>
  getPspListUsingGETDecoder();

/****************************************************************
 * getAllPspsUsingGET
 */

// Request type definition
export type GetAllPspsUsingGETT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly idWallet: string;
    readonly idPayment: string;
    readonly language: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, PspListResponseCD, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getAllPspsUsingGETDefaultResponses = {
  200: PspListResponseCD,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetAllPspsUsingGETResponsesT<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getAllPspsUsingGETDecoder<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetAllPspsUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getAllPspsUsingGETDefaultResponses as unknown) as GetAllPspsUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getAllPspsUsingGETDefaultDecoder = () =>
  getAllPspsUsingGETDecoder();

/****************************************************************
 * getSelectedPspUsingGET
 */

// Request type definition
export type GetSelectedPspUsingGETT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly idWallet: string;
    readonly idPayment: string;
    readonly language: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, PspListResponseCD, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getSelectedPspUsingGETDefaultResponses = {
  200: PspListResponseCD,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetSelectedPspUsingGETResponsesT<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getSelectedPspUsingGETDecoder<
  A0 = PspListResponseCD,
  C0 = PspListResponseCD,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetSelectedPspUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getSelectedPspUsingGETDefaultResponses as unknown) as GetSelectedPspUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getSelectedPspUsingGETDefaultDecoder = () =>
  getSelectedPspUsingGETDecoder();

/****************************************************************
 * getPspUsingGET
 */

// Request type definition
export type GetPspUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Authorization",
  never,
  | r.IResponseType<200, PspResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPspUsingGETDefaultResponses = {
  200: PspResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPspUsingGETResponsesT<
  A0 = PspResponse,
  C0 = PspResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPspUsingGETDecoder<
  A0 = PspResponse,
  C0 = PspResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPspUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspUsingGETDefaultResponses as unknown) as GetPspUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPspUsingGETDefaultDecoder = () => getPspUsingGETDecoder();

/****************************************************************
 * getResourcesUsingGET
 */

// Request type definition
export type GetResourcesUsingGETT = r.IGetApiRequestType<
  {},
  never,
  never,
  | r.IResponseType<200, ResourcesResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getResourcesUsingGETDefaultResponses = {
  200: ResourcesResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetResourcesUsingGETResponsesT<
  A0 = ResourcesResponse,
  C0 = ResourcesResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getResourcesUsingGETDecoder<
  A0 = ResourcesResponse,
  C0 = ResourcesResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetResourcesUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getResourcesUsingGETDefaultResponses as unknown) as GetResourcesUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getResourcesUsingGETDefaultDecoder = () =>
  getResourcesUsingGETDecoder();

/****************************************************************
 * getImageWithMediaTypeUsingGET
 */

// Request type definition
export type GetImageWithMediaTypeUsingGETT = r.IGetApiRequestType<
  { readonly imgName: string },
  never,
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getImageWithMediaTypeUsingGETDefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetImageWithMediaTypeUsingGETResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getImageWithMediaTypeUsingGETDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<
        GetImageWithMediaTypeUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getImageWithMediaTypeUsingGETDefaultResponses as unknown) as GetImageWithMediaTypeUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getImageWithMediaTypeUsingGETDefaultDecoder = () =>
  getImageWithMediaTypeUsingGETDecoder();

/****************************************************************
 * getPspLogoUsingGET
 */

// Request type definition
export type GetPspLogoUsingGETT = r.IGetApiRequestType<
  { readonly id: number },
  never,
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPspLogoUsingGETDefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPspLogoUsingGETResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPspLogoUsingGETDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPspLogoUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspLogoUsingGETDefaultResponses as unknown) as GetPspLogoUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPspLogoUsingGETDefaultDecoder = () =>
  getPspLogoUsingGETDecoder();

/****************************************************************
 * getServiceLogoUsingGET
 */

// Request type definition
export type GetServiceLogoUsingGETT = r.IGetApiRequestType<
  { readonly id: number },
  never,
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getServiceLogoUsingGETDefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetServiceLogoUsingGETResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getServiceLogoUsingGETDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetServiceLogoUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getServiceLogoUsingGETDefaultResponses as unknown) as GetServiceLogoUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getServiceLogoUsingGETDefaultDecoder = () =>
  getServiceLogoUsingGETDecoder();

/****************************************************************
 * addWalletSatispayUsingPOST
 */

// Request type definition
export type AddWalletSatispayUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly satispayRequest: SatispayRequest },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletV2Response, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const addWalletSatispayUsingPOSTDefaultResponses = {
  200: WalletV2Response,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type AddWalletSatispayUsingPOSTResponsesT<
  A0 = WalletV2Response,
  C0 = WalletV2Response,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function addWalletSatispayUsingPOSTDecoder<
  A0 = WalletV2Response,
  C0 = WalletV2Response,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        AddWalletSatispayUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((addWalletSatispayUsingPOSTDefaultResponses as unknown) as AddWalletSatispayUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const addWalletSatispayUsingPOSTDefaultDecoder = () =>
  addWalletSatispayUsingPOSTDecoder();

/****************************************************************
 * getConsumerUsingGET
 */

// Request type definition
export type GetConsumerUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string },
  "Authorization",
  never,
  | r.IResponseType<200, RestSatispayResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getConsumerUsingGETDefaultResponses = {
  200: RestSatispayResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetConsumerUsingGETResponsesT<
  A0 = RestSatispayResponse,
  C0 = RestSatispayResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getConsumerUsingGETDecoder<
  A0 = RestSatispayResponse,
  C0 = RestSatispayResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetConsumerUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getConsumerUsingGETDefaultResponses as unknown) as GetConsumerUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getConsumerUsingGETDefaultDecoder = () =>
  getConsumerUsingGETDecoder();

/****************************************************************
 * getTransactionsUsingGET
 */

// Request type definition
export type GetTransactionsUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string },
  "Authorization",
  never,
  | r.IResponseType<200, TransactionListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getTransactionsUsingGETDefaultResponses = {
  200: TransactionListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetTransactionsUsingGETResponsesT<
  A0 = TransactionListResponse,
  C0 = TransactionListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getTransactionsUsingGETDecoder<
  A0 = TransactionListResponse,
  C0 = TransactionListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetTransactionsUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getTransactionsUsingGETDefaultResponses as unknown) as GetTransactionsUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getTransactionsUsingGETDefaultDecoder = () =>
  getTransactionsUsingGETDecoder();

/****************************************************************
 * getTransactionUsingGET
 */

// Request type definition
export type GetTransactionUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Authorization",
  never,
  | r.IResponseType<200, TransactionResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getTransactionUsingGETDefaultResponses = {
  200: TransactionResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetTransactionUsingGETResponsesT<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getTransactionUsingGETDecoder<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetTransactionUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getTransactionUsingGETDefaultResponses as unknown) as GetTransactionUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getTransactionUsingGETDefaultDecoder = () =>
  getTransactionUsingGETDecoder();

/****************************************************************
 * checkStatusUsingGET
 */

// Request type definition
export type CheckStatusUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly id: string },
  "Authorization",
  never,
  | r.IResponseType<200, TransactionStatusResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const checkStatusUsingGETDefaultResponses = {
  200: TransactionStatusResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type CheckStatusUsingGETResponsesT<
  A0 = TransactionStatusResponse,
  C0 = TransactionStatusResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function checkStatusUsingGETDecoder<
  A0 = TransactionStatusResponse,
  C0 = TransactionStatusResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<CheckStatusUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((checkStatusUsingGETDefaultResponses as unknown) as CheckStatusUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const checkStatusUsingGETDefaultDecoder = () =>
  checkStatusUsingGETDecoder();

/****************************************************************
 * resumeUsingPOST
 */

// Request type definition
export type ResumeUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly id: string;
    readonly resumeRequest: ResumeRequest;
    readonly language?: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const resumeUsingPOSTDefaultResponses = {
  200: t.undefined,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type ResumeUsingPOSTResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function resumeUsingPOSTDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<ResumeUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((resumeUsingPOSTDefaultResponses as unknown) as ResumeUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const resumeUsingPOSTDefaultDecoder = () => resumeUsingPOSTDecoder();

/****************************************************************
 * getUserUsingGET
 */

// Request type definition
export type GetUserUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string },
  "Authorization",
  never,
  | r.IResponseType<200, UserResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getUserUsingGETDefaultResponses = {
  200: UserResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetUserUsingGETResponsesT<
  A0 = UserResponse,
  C0 = UserResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getUserUsingGETDecoder<
  A0 = UserResponse,
  C0 = UserResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetUserUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getUserUsingGETDefaultResponses as unknown) as GetUserUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getUserUsingGETDefaultDecoder = () => getUserUsingGETDecoder();

/****************************************************************
 * sendOtpUsingPOST
 */

// Request type definition
export type SendOtpUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly sendOtpRequest: SendOtpRequest;
    readonly language?: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const sendOtpUsingPOSTDefaultResponses = {
  200: t.undefined,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type SendOtpUsingPOSTResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function sendOtpUsingPOSTDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        SendOtpUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((sendOtpUsingPOSTDefaultResponses as unknown) as SendOtpUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const sendOtpUsingPOSTDefaultDecoder = () => sendOtpUsingPOSTDecoder();

/****************************************************************
 * startSessionUsingGET
 */

// Request type definition
export type StartSessionUsingGETT = r.IGetApiRequestType<
  { readonly token: string },
  never,
  never,
  | r.IResponseType<200, SessionResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const startSessionUsingGETDefaultResponses = {
  200: SessionResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type StartSessionUsingGETResponsesT<
  A0 = SessionResponse,
  C0 = SessionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function startSessionUsingGETDecoder<
  A0 = SessionResponse,
  C0 = SessionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<StartSessionUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((startSessionUsingGETDefaultResponses as unknown) as StartSessionUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const startSessionUsingGETDefaultDecoder = () =>
  startSessionUsingGETDecoder();

/****************************************************************
 * verifyOtpUsingPOST
 */

// Request type definition
export type VerifyOtpUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly verifyOtpRequest: VerifyOtpRequest },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, UserResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const verifyOtpUsingPOSTDefaultResponses = {
  200: UserResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type VerifyOtpUsingPOSTResponsesT<
  A0 = UserResponse,
  C0 = UserResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function verifyOtpUsingPOSTDecoder<
  A0 = UserResponse,
  C0 = UserResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        VerifyOtpUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((verifyOtpUsingPOSTDefaultResponses as unknown) as VerifyOtpUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const verifyOtpUsingPOSTDefaultDecoder = () =>
  verifyOtpUsingPOSTDecoder();

/****************************************************************
 * getWalletsUsingGET
 */

// Request type definition
export type GetWalletsUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string },
  "Authorization",
  never,
  | r.IResponseType<200, WalletListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getWalletsUsingGETDefaultResponses = {
  200: WalletListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetWalletsUsingGETResponsesT<
  A0 = WalletListResponse,
  C0 = WalletListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getWalletsUsingGETDecoder<
  A0 = WalletListResponse,
  C0 = WalletListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetWalletsUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getWalletsUsingGETDefaultResponses as unknown) as GetWalletsUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getWalletsUsingGETDefaultDecoder = () =>
  getWalletsUsingGETDecoder();

/****************************************************************
 * addWalletUsingPOST
 */

// Request type definition
export type AddWalletUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly walletRequest: WalletRequest;
    readonly language: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const addWalletUsingPOSTDefaultResponses = {
  200: WalletResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type AddWalletUsingPOSTResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function addWalletUsingPOSTDecoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        AddWalletUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((addWalletUsingPOSTDefaultResponses as unknown) as AddWalletUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const addWalletUsingPOSTDefaultDecoder = () =>
  addWalletUsingPOSTDecoder();

/****************************************************************
 * checkCardBinUsingPOST
 */

// Request type definition
export type CheckCardBinUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly checkCardBinRequest: CheckCardBinRequest;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, CheckCardBinResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const checkCardBinUsingPOSTDefaultResponses = {
  200: CheckCardBinResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type CheckCardBinUsingPOSTResponsesT<
  A0 = CheckCardBinResponse,
  C0 = CheckCardBinResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function checkCardBinUsingPOSTDecoder<
  A0 = CheckCardBinResponse,
  C0 = CheckCardBinResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        CheckCardBinUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((checkCardBinUsingPOSTDefaultResponses as unknown) as CheckCardBinUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const checkCardBinUsingPOSTDefaultDecoder = () =>
  checkCardBinUsingPOSTDecoder();

/****************************************************************
 * addWalletCreditCardUsingPOST
 */

// Request type definition
export type AddWalletCreditCardUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly walletRequest: WalletRequest },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
  | r.IResponseType<422, undefined, never>
>;

export const addWalletCreditCardUsingPOSTDefaultResponses = {
  200: WalletResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined,
  422: t.undefined
};

export type AddWalletCreditCardUsingPOSTResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = undefined,
  C5 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  422: t.Type<A5, C5>;
};

export function addWalletCreditCardUsingPOSTDecoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined,
  A5 = undefined,
  C5 = undefined
>(
  overrideTypes:
    | Partial<
        AddWalletCreditCardUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
  | r.IResponseType<422, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((addWalletCreditCardUsingPOSTDefaultResponses as unknown) as AddWalletCreditCardUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  const d422 = (type[422].name === "undefined"
    ? r.constantResponseDecoder<undefined, 422, never>(422, undefined)
    : r.ioResponseDecoder<
        422,
        typeof type[422]["_A"],
        typeof type[422]["_O"],
        never
      >(422, type[422])) as r.ResponseDecoder<r.IResponseType<422, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
        d403
      ),
      d404
    ),
    d422
  );
}

// Decodes the success response with the type defined in the specs
export const addWalletCreditCardUsingPOSTDefaultDecoder = () =>
  addWalletCreditCardUsingPOSTDecoder();

/****************************************************************
 * updateWalletUsingPUTV2
 */

// Request type definition
export type UpdateWalletUsingPUTV2T = r.IPutApiRequestType<
  {
    readonly Bearer: string;
    readonly id: number;
    readonly walletRequest: WalletRequest;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const updateWalletUsingPUTV2DefaultResponses = {
  200: WalletResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type UpdateWalletUsingPUTV2ResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function updateWalletUsingPUTV2Decoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        UpdateWalletUsingPUTV2ResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateWalletUsingPUTV2DefaultResponses as unknown) as UpdateWalletUsingPUTV2ResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const updateWalletUsingPUTV2DefaultDecoder = () =>
  updateWalletUsingPUTV2Decoder();

/****************************************************************
 * getWalletUsingGET
 */

// Request type definition
export type GetWalletUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getWalletUsingGETDefaultResponses = {
  200: WalletResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetWalletUsingGETResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getWalletUsingGETDecoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetWalletUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getWalletUsingGETDefaultResponses as unknown) as GetWalletUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getWalletUsingGETDefaultDecoder = () => getWalletUsingGETDecoder();

/****************************************************************
 * deleteWalletUsingDELETE
 */

// Request type definition
export type DeleteWalletUsingDELETET = r.IDeleteApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Authorization",
  never,
  | r.IResponseType<200, undefined, never>
  | r.IResponseType<204, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
>;

export const deleteWalletUsingDELETEDefaultResponses = {
  200: t.undefined,
  204: t.undefined,
  401: t.undefined,
  403: t.undefined
};

export type DeleteWalletUsingDELETEResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  204: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
};

export function deleteWalletUsingDELETEDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<DeleteWalletUsingDELETEResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<204, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteWalletUsingDELETEDefaultResponses as unknown) as DeleteWalletUsingDELETEResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d204 = (type[204].name === "undefined"
    ? r.constantResponseDecoder<undefined, 204, never>(204, undefined)
    : r.ioResponseDecoder<
        204,
        typeof type[204]["_A"],
        typeof type[204]["_O"],
        never
      >(204, type[204])) as r.ResponseDecoder<r.IResponseType<204, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d204), d401),
    d403
  );
}

// Decodes the success response with the type defined in the specs
export const deleteWalletUsingDELETEDefaultDecoder = () =>
  deleteWalletUsingDELETEDecoder();

/****************************************************************
 * confirmWalletUsingPOST
 */

// Request type definition
export type ConfirmWalletUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const confirmWalletUsingPOSTDefaultResponses = {
  200: WalletResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type ConfirmWalletUsingPOSTResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function confirmWalletUsingPOSTDecoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        ConfirmWalletUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((confirmWalletUsingPOSTDefaultResponses as unknown) as ConfirmWalletUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const confirmWalletUsingPOSTDefaultDecoder = () =>
  confirmWalletUsingPOSTDecoder();

/****************************************************************
 * favouriteWalletUsingPOST
 */

// Request type definition
export type FavouriteWalletUsingPOSTT = r.IPostApiRequestType<
  { readonly Bearer: string; readonly id: number },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const favouriteWalletUsingPOSTDefaultResponses = {
  200: WalletResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type FavouriteWalletUsingPOSTResponsesT<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function favouriteWalletUsingPOSTDecoder<
  A0 = WalletResponse,
  C0 = WalletResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<
        FavouriteWalletUsingPOSTResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((favouriteWalletUsingPOSTDefaultResponses as unknown) as FavouriteWalletUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const favouriteWalletUsingPOSTDefaultDecoder = () =>
  favouriteWalletUsingPOSTDecoder();

/****************************************************************
 * pay3dsUsingPOST
 */

// Request type definition
export type Pay3dsUsingPOSTT = r.IPostApiRequestType<
  {
    readonly Bearer: string;
    readonly id: string;
    readonly payRequest: PayRequest;
    readonly language?: string;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, TransactionResponse, never>
  | r.IResponseType<201, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const pay3dsUsingPOSTDefaultResponses = {
  200: TransactionResponse,
  201: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type Pay3dsUsingPOSTResponsesT<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
> = {
  200: t.Type<A0, C0>;
  201: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
};

export function pay3dsUsingPOSTDecoder<
  A0 = TransactionResponse,
  C0 = TransactionResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = undefined,
  C4 = undefined
>(
  overrideTypes:
    | Partial<Pay3dsUsingPOSTResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<201, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
  | r.IResponseType<404, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((pay3dsUsingPOSTDefaultResponses as unknown) as Pay3dsUsingPOSTResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, never>(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        never
      >(201, type[201])) as r.ResponseDecoder<r.IResponseType<201, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d201), d401),
      d403
    ),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const pay3dsUsingPOSTDefaultDecoder = () => pay3dsUsingPOSTDecoder();

/****************************************************************
 * getWalletsV2UsingGET
 */

// Request type definition
export type GetWalletsV2UsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string },
  "Authorization",
  never,
  | r.IResponseType<200, WalletV2ListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getWalletsV2UsingGETDefaultResponses = {
  200: WalletV2ListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetWalletsV2UsingGETResponsesT<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getWalletsV2UsingGETDecoder<
  A0 = WalletV2ListResponse,
  C0 = WalletV2ListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetWalletsV2UsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getWalletsV2UsingGETDefaultResponses as unknown) as GetWalletsV2UsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getWalletsV2UsingGETDefaultDecoder = () =>
  getWalletsV2UsingGETDecoder();

/****************************************************************
 * changePayOption
 */

// Request type definition
export type ChangePayOptionT = r.IPutApiRequestType<
  {
    readonly Bearer: string;
    readonly idWallet: number;
    readonly payRequest: WalletPaymentStatusRequest;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, WalletV2Response, never>
  | r.IResponseType<400, undefined, never>
  | r.IResponseType<404, undefined, never>
  | r.IResponseType<500, undefined, never>
>;

export const changePayOptionDefaultResponses = {
  200: WalletV2Response,
  400: t.undefined,
  404: t.undefined,
  500: t.undefined
};

export type ChangePayOptionResponsesT<
  A0 = WalletV2Response,
  C0 = WalletV2Response,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function changePayOptionDecoder<
  A0 = WalletV2Response,
  C0 = WalletV2Response,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<ChangePayOptionResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((changePayOptionDefaultResponses as unknown) as ChangePayOptionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const changePayOptionDefaultDecoder = () => changePayOptionDecoder();

/****************************************************************
 * deleteWalletsByServiceUsingDELETE
 */

// Request type definition
export type DeleteWalletsByServiceUsingDELETET = r.IDeleteApiRequestType<
  { readonly Bearer: string; readonly service: string },
  "Authorization",
  never,
  | r.IResponseType<200, DeletedWalletsResponse, never>
  | r.IResponseType<204, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
>;

export const deleteWalletsByServiceUsingDELETEDefaultResponses = {
  200: DeletedWalletsResponse,
  204: t.undefined,
  401: t.undefined,
  403: t.undefined
};

export type DeleteWalletsByServiceUsingDELETEResponsesT<
  A0 = DeletedWalletsResponse,
  C0 = DeletedWalletsResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  204: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
};

export function deleteWalletsByServiceUsingDELETEDecoder<
  A0 = DeletedWalletsResponse,
  C0 = DeletedWalletsResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<
        DeleteWalletsByServiceUsingDELETEResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<204, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<403, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteWalletsByServiceUsingDELETEDefaultResponses as unknown) as DeleteWalletsByServiceUsingDELETEResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d204 = (type[204].name === "undefined"
    ? r.constantResponseDecoder<undefined, 204, never>(204, undefined)
    : r.ioResponseDecoder<
        204,
        typeof type[204]["_A"],
        typeof type[204]["_O"],
        never
      >(204, type[204])) as r.ResponseDecoder<r.IResponseType<204, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d204), d401),
    d403
  );
}

// Decodes the success response with the type defined in the specs
export const deleteWalletsByServiceUsingDELETEDefaultDecoder = () =>
  deleteWalletsByServiceUsingDELETEDecoder();

/****************************************************************
 * getPspListV2UsingGET
 */

// Request type definition
export type GetPspListV2UsingGETT = r.IGetApiRequestType<
  {
    readonly Bearer: string;
    readonly idPayment: string;
    readonly idWallet: string;
    readonly cellphoneNumber?: string;
    readonly externalPsId?: string;
    readonly language?: string;
    readonly isList?: string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, PspDataListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPspListV2UsingGETDefaultResponses = {
  200: PspDataListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPspListV2UsingGETResponsesT<
  A0 = PspDataListResponse,
  C0 = PspDataListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPspListV2UsingGETDecoder<
  A0 = PspDataListResponse,
  C0 = PspDataListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPspListV2UsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPspListV2UsingGETDefaultResponses as unknown) as GetPspListV2UsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPspListV2UsingGETDefaultDecoder = () =>
  getPspListV2UsingGETDecoder();

/****************************************************************
 * getPaypalPspsUsingGET
 */

// Request type definition
export type GetPaypalPspsUsingGETT = r.IGetApiRequestType<
  { readonly Bearer: string; readonly language?: string },
  "Authorization",
  never,
  | r.IResponseType<200, PaypalPspListResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<403, undefined, never>
  | r.IResponseType<404, undefined, never>
>;

export const getPaypalPspsUsingGETDefaultResponses = {
  200: PaypalPspListResponse,
  401: t.undefined,
  403: t.undefined,
  404: t.undefined
};

export type GetPaypalPspsUsingGETResponsesT<
  A0 = PaypalPspListResponse,
  C0 = PaypalPspListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
};

export function getPaypalPspsUsingGETDecoder<
  A0 = PaypalPspListResponse,
  C0 = PaypalPspListResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined
>(
  overrideTypes:
    | Partial<GetPaypalPspsUsingGETResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<403, A2, never>
  | r.IResponseType<404, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaypalPspsUsingGETDefaultResponses as unknown) as GetPaypalPspsUsingGETResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, never>(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        never
      >(403, type[403])) as r.ResponseDecoder<r.IResponseType<403, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
    d404
  );
}

// Decodes the success response with the type defined in the specs
export const getPaypalPspsUsingGETDefaultDecoder = () =>
  getPaypalPspsUsingGETDecoder();
