"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapHandleExitAction = exports.hrefNoOp = exports.createEnumType = exports.EnumType = void 0;
const tslib_1 = require("tslib");
const t = tslib_1.__importStar(require("io-ts"));
class EnumType extends t.Type {
    constructor(e, name) {
        super(name || "enum", (u) => {
            if (!Object.values(this.enumObject).find((v) => v === u)) {
                return false;
            }
            // enum reverse mapping check
            if (typeof this.enumObject[u] === "number") {
                return false;
            }
            return true;
        }, (u, c) => (this.is(u) ? t.success(u) : t.failure(u, c)), t.identity);
        this._tag = "EnumType";
        this.enumObject = e;
    }
}
exports.EnumType = EnumType;
function createEnumType(e, name) {
    return new EnumType(e, name);
}
exports.createEnumType = createEnumType;
exports.hrefNoOp = "#!";
const wrapHandleExitAction = (href, onClick, onExit) => (e) => {
    e.preventDefault();
    if (onExit) {
        onExit(onClick ? onClick : () => window.location.assign(href));
    }
    else if (onClick) {
        onClick();
    }
    else {
        window.location.assign(href);
    }
};
exports.wrapHandleExitAction = wrapHandleExitAction;
